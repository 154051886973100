<template>
  <b-container id="content-verification" fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side"> </b-col>
      <b-col
        cols="12"
        md="6"
        class="right-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <span>
            <div data-anima="top">
              <p class="text">
                Enviamos um link de verificação no e-mail no momento do seu
                Cadastro, Caso não tenha recebido, podera clicar no botão a
                baixo.
              </p>
            </div>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
                <!-- <button class="btn-login button button-primary" @click="onSubmit()">Enviar novo link de verificação</button> -->
                <BaseButton variant="info3" @click="onSubmit()"
                  >Enviar novo link de verificação</BaseButton
                >

                <a href="#" @click.prevent="logout" class="logout"
                  >Acessar outra conta</a
                >
              </b-overlay>
            </b-row>

            <b-row class="mt-5">
              <a
                class="links-help"
                href="https://www.voomp.com.br/politica-de-privacidade-greenn/"
                target="_blank"
                >Política de Privacidade</a
              >
            </b-row>
          </span>
        </div>

        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
          <p class="recupera-senha-text ml-4">Processando ...</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import EmailVerificationService from "@/services/resources/EmailVerificationService";
const serviceEmail = EmailVerificationService.build();

import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import Cookies from "js-cookie";
// import crisp from '@/mixins/crisp.js'

export default {
  name: "Verificação",
  data() {
    return {
      email: "",
      success: true,
      loading: false,
      hash: null,
      interval: null,
    };
  },
  // mixins: [crisp],
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    onSubmit() {
      this.loading = true;
      let data = {
        email: this.$store.getters.getProfile.email,
      };
      serviceEmail
        .create(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast("Novo link de verificação foi enviado", {
              title: "Verificação",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verificaDados() {
      this.$store
        .dispatch("emailVerification")
        .then((resp) => {
          if (resp) {
            this.$router.push(`/dashboard${window.location.search}`);
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getHash() {
      this.loading = true;
      let data = {
        id: this.$route.params.hash,
      };
      serviceEmail
        .update(data)
        .then((resp) => {
          if (this.$store.getters.isAuthenticated) {
            if (resp.success) {
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              clearInterval(this.interval);
              window.location.reload();
            } else {
              if (this.$store.getters.verifyEmail) {
                this.$router
                  .push(`/dashboard${window.location.search}`)
                  .catch();
                return;
              } else {
                this.verificaDados();
              }
            }
          } else {
            if (resp.success) {
              clearInterval(this.interval);
              let access_token = "Bearer " + resp.access_token;
              Cookies.set("access_token", access_token, {
                expires: 365,
              });
              this.$store.state.auth.access_token = access_token;
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.verificaDados();
        });
    },
    initVerify() {
      if (this.$route.params.hash) {
        this.getHash();
      } else if (this.$store.getters.verifyEmail) {
        this.$router.push(`/dashboard${window.location.search}`).catch();
        return;
      } else {
        this.verificaDados();
      }
    },
    pollData() {
      this.interval = setInterval(() => {
        this.initVerify();
      }, 10000);
    },

    addMeta() {
      let data = {
        meta_key: "onboarding",
        meta_value: 0,
      };
      serviceMeta.create(data);
    },

    fetchMetas() {
      if (this.$store.state.isAuthenticated) {
        let data = {
          id: "onboarding",
        };
        serviceMeta.read(data).then((response) => {
          if (typeof response.meta_value == "undefined") {
            this.addMeta();
          }
        });
      }
    },
    installTags() {
      // tags facebook
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      fbq("init", "783302982379407");
      fbq("track", "PageView");

      // fim tags facebook

      // ------

      // tags google
      let googleScript = document.createElement("script");
      googleScript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=AW-669444824"
      );
      googleScript.async = true;
      document.head.appendChild(googleScript);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-669444824");
      gtag("event", "conversion", {
        send_to: "AW-669444824/mkFhCLKXrdoCENjVm78C",
      });

      // fim tags google

      // inicio pixel tiktok

      !(function(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
        ]),
          (ttq.setAndDefer = function(t, e) {
            t[e] = function() {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function(t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function(e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement("script");
            (o.type = "text/javascript"),
              (o.async = !0),
              (o.src = i + "?sdkid=" + e + "&lib=" + t);
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load("C5O60ULO3VNUQLVLNK00");
        ttq.page();
      })(window, document, "ttq");

      // fim pixel tiktok
    },
  },
  mounted() {
    this.loading = true;
    this.installTags();
    this.fetchMetas();
    this.initVerify();
    this.pollData();

    // add crisp no greenn

    // let notify = document.getElementById("1WZET722PJibpmL_crisp");
    // if (notify === null) {
    //   window.$crisp = [];

    //   this.crispWitUser();
    //   this.appendScriptCrisp();
    // } else {
    //   const crisp = document.querySelector(".crisp-client");
    //   crisp.style.display = "block";
    //   this.crispWitUser(false);
    // }
    // fim crisp
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}

.bg-login {
  background: $base-color;
}

.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px;
  height: 100%;

  .inner-wrapper {
    margin-top: 90px;
    width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}
.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
}
.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}
// .btn-acoes {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
@media screen and (max-width: 768px) {
  .inner-wrapper {
    width: 85% !important;
  }
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .links-help {
    margin: 0;
  }
}

.text,
.logout {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}

.logout {
  display: block;
  color: #333;
  margin-top: 20px;
}
</style>

<style>
.main {
  padding: 0px !important;
}
</style>
